<template>
	<div class="hello">
		<GlobalHeader />
		<div v-if="loaded" class="container mrgnbtm">
			<div v-if="user?.user">
				<div class="col-12" style="flex-grow:1">
					<div class="d-flex justify-content-center">
						Jouw unieke link: <a
							:href="'https://de-geuzen.nl/webshop?lid=' + user.user">https://de-geuzen.nl/webshop?lid={{
								user.user }}</a>
					</div>
				</div>
				<div class="row">
					<div class="col-md-8">
						<CreateOrder @createOrder="orderCreate($event)" />
					</div>
					<div class="col-md-4">
						<DisplayBoard :numberOfOrders="numberOfOrders" :bagsSold="bagsSold" />
					</div>
				</div>
				<div class="row mrgnbtm">
					<OrderOverview v-if="orders.length > 0" :orders="orders" @getAllOrders="getAllOrders()" />
				</div>
			</div>
			<div v-else class="row mrgnbtm">
				<loginComp />
			</div>
		</div>
	</div>
</template>

<script>
import GlobalHeader from "./GlobalHeader.vue";
import loginComp from "./loginComp.vue";
import CreateOrder from "./CreateOrder.vue";
import DisplayBoard from "./DisplayBoard.vue";
import OrderOverview from "./OrderOverview.vue";
import { getAllOrders } from "../services/OrderService";
import { getUser } from "../services/UserService";

export default {
	name: "MainDashboard",
	components: {
		GlobalHeader,
		loginComp,
		CreateOrder,
		DisplayBoard,
		OrderOverview
	},
	data() {
		return {
			orders: [],
			numberOfOrders: 0,
			bagsSold: 0,
			user: {},
			loaded: false
		};
	},
	methods: {
		getUser() {
			getUser().then((response) => {
				this.user = response;
			}).finally(() => {
				this.loaded = true;
			})
		},
		getAllOrders() {
			getAllOrders().then((response) => {
				console.log('orders', response);
				this.orders = response || [];
				this.bagsSold = 0;
				this.orders.forEach((ord) => {
					this.bagsSold += ord.bagsSold;
				});
				this.numberOfOrders = this.orders.length;
			});
		},
		async orderCreate() {
			// let createResponse;
			// await createOrder(data).then((response) => {
			// 	createResponse = response;
			// });
			this.getAllOrders();
			// return createResponse;
		}
	},
	mounted() {
		this.getUser();
		this.getAllOrders();
	}
};
</script>
