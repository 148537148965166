<template>
	<div class="container">
		<div class="row">
			<div class="col-md-7 mrgnbtm">
				<h2>Create Order</h2>
				<form @submit.prevent="sendOrder">
					<div class="row">
						<div class="form-group col-2">
							<label htmlFor="exampleInputEmail1">Bedrijf?</label>
							<input type="checkbox" class="form-control" v-model="isCompany" name="isCompany" />
						</div>
						<div v-if="isCompany" class="form-group col">
							<label htmlFor="exampleInputPassword1">Bedrijfsnaam</label>
							<input required type="text" class="form-control" v-model="company" name="company"
								placeholder="Bedrijfsnaam" />
						</div>
					</div>
					<div class="row">
						<div class="form-group col-md-6">
							<label htmlFor="exampleInputEmail1">Voornaam</label>
							<input type="text" class="form-control" v-model="firstName" name="firstname"
								aria-describedby="emailHelp" placeholder="Voornaam" />
						</div>
						<div class="form-group col-md-6">
							<label htmlFor="exampleInputPassword1">Achternaam</label>
							<input required type="text" class="form-control" v-model="lastName" name="lastname"
								placeholder="Achternaam" />
						</div>
					</div>
					<div class="row">
						<div class="form-group col-md-6">
							<label htmlFor="exampleInputEmail1">Email</label>
							<input type="email" class="form-control" v-model="email" name="email"
								aria-describedby="emailHelp" placeholder="Email" />
						</div>
						<div class="form-group col-md-6">
							<label htmlFor="exampleInputEmail1">Telefoon Nummer</label>
							<input type="tel" class="form-control" v-model="phone" name="phone"
								aria-describedby="phoneHelp" placeholder="Telefoon Nummer" />
						</div>
					</div>

					<div style="width: 100%" class="btn-group" role="group" aria-label="Basic example">
						<button type="button" @click="switchDelivery(true)"
							:class="isDelivery ? 'btn btn-primary' : 'btn btn-light'">
							Bezorgen
						</button>
						<button type="button" @click="switchDelivery(false)"
							:class="!isDelivery ? 'btn btn-primary' : 'btn btn-light'">
							Ophalen
						</button>
					</div>
					<br />
					<br />
					<template v-if="isDelivery">
						<div class="row">
							<div class="form-group col-md-12">
								<label htmlFor="map">Zoeken</label>
								<vue-google-autocomplete id="vue-google-autocomplete" classname="form-control"
									placeholder="Start typing" country="nl" :fields="googleFields"
									v-on:placechanged="getAddressData" />

								<br />

								<label htmlFor="map">Adres</label>
								<input required type="text" class="form-control" v-model="street" name="street"
									placeholder="Straat" />
								<input required type="text" class="form-control" v-model="houseNumber"
									name="houseNumber" placeholder="HuisNummer" />
								<input required type="text" class="form-control" v-model="postalCode" name="postalCode"
									placeholder="Postcode" />
								<input required type="text" class="form-control" v-model="city" name="city"
									placeholder="Stad" />
							</div>
						</div>
					</template>
					<div class="row">
						<div class="form-group col-md-6">
							<label htmlFor="aantalMet">Aantal Met</label>
							<input type="number" step="1" pattern="[0-9]" class="form-control" v-model="aantalMet"
								name="aantalMet" placeholder="Met" />
						</div>
						<div class="form-group col-md-6">
							<label htmlFor="aantalZonder">Aantal Zonder</label>
							<input type="number" step="1" pattern="[0-9]" class="form-control" v-model="aantalZonder"
								name="aantalZonder" placeholder="Zonder" />
						</div>
					</div>
					<div v-if="blockCreate">Onbekende gebruiker</div>
					<div v-if="undeliverable">Wij leveren niet op deze postcode</div>
					<div v-if="noBollen">Selecteer aantal oliebolen</div>
					<pulse-loader v-if="loading" :loading="loading"></pulse-loader>
					<button v-else :disabled="createDisabled" type="submit" class="btn btn-danger">Aanmaken</button>
					<br />
					<br />
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import VueGoogleAutocomplete from "./VueGoogleAutocomplete.vue";
import { getZones, getProducts, createOrder } from "../services/OrderService";
import { getUser } from "../services/UserService";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
	name: "CreateOrder",
	data() {
		return {
			firstName: "",
			lastName: "",
			email: "",
			phone: "",
			street: "",
			houseNumber: "",
			postalCode: "",
			city: "",
			aantalMet: "",
			aantalZonder: "",
			cashPaid: false,
			selected: null,
			blockCreate: true,
			prods: undefined,
			googleFields: ["adr_address"],
			loading: true,
			isDelivery: true,
			isCompany: false,
			allowedPostalCodes: []
		};
	},
	computed: {
		createDisabled() {
			return this.blockCreate || this.undeliverable || this.noBollen;
		},
		undeliverable() {
			return (
				this.isDelivery &&
				this.postalCode &&
				!this.postalCode.length < 6 &&
				!this.allowedPostalCodes.includes(this.postalCode.substring(0, 4))
			);
		},
		noBollen() {
			return (!this.isDelivery || this.postalCode) && !this.aantalMet && !this.aantalZonder;
		}
	},
	components: {
		VueGoogleAutocomplete,
		PulseLoader
	},
	methods: {
		async initUser() {
			let user = await getUser();
			if (user.row) {
				this.blockCreate = false;
				this.getProds();
				this.getZones();
			}
			this.loading = false;
		},
		async getProds() {
			if (!this.prods) {
				this.prods = await getProducts();
			}
			return this.prods;
		},
		async getZones() {
			if (!this.allowedPostalCodes || this.allowedPostalCodes.length === 0) {
				this.allowedPostalCodes = await getZones();
			}
			// console.log('zones: ', JSON.parse(JSON.stringify(this.allowedPostalCodes)));
			return this.allowedPostalCodes;
		},
		async sendOrder() {
			this.loading = true;
			let order = {
				set_paid: false,
				status: "on-hold",
				billing: {
					first_name: this.firstName,
					last_name: this.lastName,
					country: "NL"

				},
				line_items: []
			};
			if (this.isCompany) {
				order.billing.company = this.company;
			}
			if (this.isDelivery) {
				order.billing.address_1 = this.street + " " + this.houseNumber;
				order.billing.city = this.city;
				order.billing.postcode = this.postalCode;
			} else {
				order.billing.address_1 = "Ophalen bij de geuzen op Honkpad 1";
				order.billing.city = "Alkmaar";
				order.billing.postcode = "1816 LZ";
			}

			order.shipping = JSON.parse(JSON.stringify(order.billing));
			let shippingLine = {};
			shippingLine.total = "0";
			shippingLine.method_id = this.isDelivery ? "2" : "local_pickup";
			shippingLine.method_title = this.isDelivery ? 'Bezorgen' : 'Ophalen';
			// 0 = pickup
			// 2 = bezorging regio alkmaar
			// 3 = Nederland afhalen
			order.shipping_lines = [shippingLine];

			if (this.phone) {
				order.billing.phone = this.phone;
			}
			if (this.email) {
				order.billing.email = this.email.toLowerCase();
			} else {
				order.status = "pending";
			}
			let prods = await this.getProds();
			if (!this.aantalMet && !this.aantalZonder) {
				this.loading = false;
				return;
			}
			if (this.aantalMet && this.aantalMet > 0) {
				order.line_items.push({
					product_id: prods.met.id,
					quantity: this.aantalMet
				});
			}
			if (this.aantalZonder && this.aantalZonder > 0) {
				order.line_items.push({
					product_id: prods.zonder.id,
					quantity: this.aantalZonder
				});
			}
			// let createResponse = await createOrder(order);
			await createOrder(order);
			console.log("order created");
			// console.log("orderResponse", createResponse);
			this.$emit("createOrder");
			this.clearForm();
			this.loading = false;
		},
		clearForm() {
			this.firstName = "";
			this.lastName = "";
			this.email = "";
			this.street = "";
			this.houseNumber = "";
			this.postalCode = "";
			this.city = "";
			this.aantalMet = "";
			this.aantalZonder = "";
			this.phone = "";
			this.isDelivery = true;
			this.isCompany = false;
		},
		/**
		 * When the location found
		 * @param {Object} addressData Data of the found location
		 * @param {Object} placeResultData PlaceResult object
		 * @param {String} id Input container ID
		 */
		getAddressData: function (addressData) {
			console.log("addressData", addressData);
			if (!this.allowedPostalCodes.includes(addressData.post)) {
				// show error;
			}
			this.street = addressData.route;
			this.houseNumber = addressData.street_number;
			this.postalCode = addressData.postal_code;
			this.city = addressData.locality;
			// this.address = addressData;
		},
		switchDelivery(bool) {
			this.isDelivery = bool;
		},
		switchCompany(bool) {
			this.isCompany = bool;
		}
	},
	beforeMount() {
		this.initUser();
	}
};
</script>
