<template>
	<nav>
		<router-link :to="'/'">Home</router-link> |
		<router-link :to="'/help'">Help</router-link>
		<span v-if="user?.user"> | </span>
		<a v-if="user?.user" @click="logout()" href="#">
			<span>Logout</span>
		</a>
	</nav>
	<router-view />
</template>

<style>
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
}

nav {
	padding: 30px;
}

nav a {
	font-weight: bold;
	color: #2c3e50;
}

nav a.router-link-exact-active {
	color: #42b983;
}
</style>

<script>
import { getUser, logout } from "./services/UserService";
export default {
	data() {
		return {
			user: {}
		};
	},
	methods: {
		logout() {
			logout();
		},
		getUser() {
			getUser().then((response) => {
				this.user = response;
			})
		}
	},
	mounted() {
		this.getUser();
	}
};
</script>
