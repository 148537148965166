import {createRouter, createWebHistory} from "vue-router";
import HomeView from "../views/HomeView.vue";
import HelpView from "../views/HelpView.vue";

const routes = [
	{
		path: "/",
		name: "No User",
		component: HomeView
	},
	{
		path: "/help",
		name: "help",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: HelpView
	}
];

const router = createRouter({
	history: createWebHistory(),
	routes
});

export default router;
