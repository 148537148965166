const axios = require("axios");
const {getUser, getUserHeader} = require("./UserService");

export async function getAllOrders() {
	const response = await axios.get("/api/orders", getUserHeader());
	// console.table(response.data);
	return response.data;
}

export async function createOrder(data) {
	let user = await getUser();
	if (!user.row) {
		return;
	}
	data.meta_data = [
		{
			key: "salesPerson",
			value: user.user
		}
	];
	let response = await axios.post("/api/orders", {order: data}, getUserHeader());
	// console.log("order created", response);
	return response.data;
}

export async function getZones() {
	const response = await axios.get("/api/zones");
	console.log("get zones response", response);
	return response.data;
}
export async function getProducts() {
	// console.log("getProducts");
	const response = await axios.get("/api/products");
	// console.log("get products response", response);
	return response.data;
}
