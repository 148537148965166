<template>
	<MainDashboard />
</template>

<script>
// @ is an alias to /src
import MainDashboard from "@/components/MainDashboard.vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

export default {
	name: "HomeView",
	components: {
		MainDashboard
	}
};
</script>
