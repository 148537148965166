<template>
	<div class="display-board">
		<h4>Zakken oliebollen verkocht: {{ bagsSold }}</h4>
	</div>
</template>

<script>
export default {
	name: "DisplayBoard",
	props: ["numberOfOrders", "bagsSold"],
	methods: {
		getAllOrders() {
			this.$emit("getAllOrders");
		}
	}
};
</script>
