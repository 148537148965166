<template>
	<div class="container">
		<div class="row">
			<div class="col-md-12 mrgnbtm">
				<h2>Login</h2>
				<form @submit.prevent="login">
					<div class="row">
						<div class="form-group col-md-12">
							<label>Lid Nummer</label>
							<input type="text" class="form-control" v-model="user" name="user"
								aria-describedby="emailHelp" placeholder="Lid Nummer" />
						</div>
						<div class="form-group col-md-12">
							<label>Geboortedatum</label>
							<VueDatePicker required v-model="bDay" :flow="['year', 'month', 'calendar']" auto-apply
								:max-date="new Date()" :enable-time-picker="false" format="dd-MM-yyyy" text-input />
						</div>
						<span>{{ msg }}</span>
					</div>

					<pulse-loader v-if="loading" :loading="loading"></pulse-loader>
					<button v-else :disabled="loginDisabled" type="submit" class="btn btn-danger">Login</button>
					<br />
					<br />
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import { login } from "../services/UserService";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
	name: "loginComp",
	data() {
		return {
			user: "",
			bDay: "",
			msg: ""
		};
	},
	computed: {
		loginDisabled() {
			return !this.user || !this.bDay;
		},
	},
	components: {
		PulseLoader,
		VueDatePicker
	},
	methods: {
		async login() {
			this.msg = await login(this.user, new Date(this.bDay.toDateString()));
		}
	},
	beforeMount() {
		// this.initUser();
	}
};
</script>
