const axios = require("axios");
const TOKENKEY = 'token';

export function logout(){
	eraseCookie(TOKENKEY);
	window.location.replace('/');
}
export async function login(userNr, bDay){
	let convertedBDay = Math.round(25569.0 + ((bDay.getTime()) / (1000 * 60 * 60 * 24)));
	let token = btoa(userNr + ':' + convertedBDay);
	setCookie(TOKENKEY, token, 30);
	let user = await getUser();
	if(user.row) {
		window.location.replace('/');
	} else {
		return 'Invalid login';
	}
	// useCookies;
}

export async function getUser() {
	const response = await axios.get("/api/users/",getUserHeader());
	return response.data;
}

export function getUserHeader() {
	return {
		headers: {
			Authorization: getCookie(TOKENKEY)
		}
	};
}
export async function getUserId() {
	return getUser()?.user;
}

function getCookie(cname) {
	let name = cname + "=";
	let decodedCookie = decodeURIComponent(document.cookie);
	let ca = decodedCookie.split(';');
	for(let i = 0; i <ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) == ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "";
}

function setCookie(cname, cvalue, exdays) {
	const d = new Date();
	d.setTime(d.getTime() + (exdays*24*60*60*1000));
	let expires = "expires="+ d.toUTCString();
	document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function eraseCookie(name) {   
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}
